// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { getJson, postJson } from 'Utils/http';
import { API_URL } from 'Config';
import { RootState } from 'Store';
import { getOrders } from 'Features/order/orderSelectors';
import { UpdateImageryRequestSuccessAction } from 'Features/project/projectActions';
import { UpdateProjectListImageryRequestSuccessAction } from 'Features/projectList/projectListActions';
import { CreateImageryRequestFailedAction, CreateImageryRequestStartAction, CreateImageryRequestSuccessAction, GetImageryCoverageFailedAction, GetImageryCoverageStartAction, GetImageryCoverageSuccessAction, GetImageryProvidersFailedAction, GetImageryProvidersStartAction, GetImageryProvidersSuccessAction, SetAvailableProjectionsAction } from './imageryActions';

export const GetImageryProvidersThunk = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const url = `${API_URL}/imageryProviders`;
  const { token } = getState().auth;

  dispatch(GetImageryProvidersStartAction());

  const { success, message, data } = await getJson<IImageryProvidersResponse>(url, token);
  if (!success) {
    dispatch(GetImageryProvidersFailedAction());
    return;
  }

  dispatch(GetImageryProvidersSuccessAction(data.imageryProviders));
};

export const GetNearmapImageryCoverageThunk = (coordinates: [number, number][]) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { token } = getState().auth;
  const url = `${API_URL}/imageryProvider/nearmapCoverage`;
  dispatch(GetImageryCoverageStartAction());
  const coordinatesString = coordinates.map((coord) => coord.join(',')).join(',');
  const data = { polygon: coordinatesString };

  const response = await postJson<INearmapCoverageResponse>(url, data, token);

  if (!response.success || !response.data.coverage) {
    const { org } = getState().account;
    const { project } = getState().project;
    const orders = getOrders(getState());

    const { companyName, imageryProviders } = org;
    const { name, _id: projectId } = project;
    const { acreage } = orders.activeOrder;

    const providerTier = getState().imagery.imageryProviders.filter((iP) => iP._id === imageryProviders[0]);

    let providerTierName = 'none';

    if (providerTier.length) {
      providerTierName = providerTier[0].name;
    }

    window?.pendo?.track('Nearmap Imagery Unavailable', {
      companyName,
      projectId,
      projectName: name,
      kmlSize: acreage?.toFixed(2),
      nearmapTier: providerTierName,
    });
  }

  if (!response.success) {
    dispatch(GetImageryCoverageFailedAction());
    return;
  }
  dispatch(GetImageryCoverageSuccessAction(response.data.coverage));
};

export const GetAvailableProjectionsThunk = (kmlBBox: number[]) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { token } = getState().auth;
  const url = `${API_URL}/imageryRequest/getProjections`;
  const data = { bbox: kmlBBox };
  const result = await postJson<any>(url, data, token);
  if (result.success) {
    dispatch(SetAvailableProjectionsAction(result.data.availableProjections));
  }
};

export const CreateImageryRequestThunk = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const { projectId } = getState().project;
    const { imageryVendorSelected, projectionSelected, imageryProcessing } = getState().imagery;

    const data = {
      provider: imageryVendorSelected,
      ownerProject: projectId,
      projection: projectionSelected,
      processImmediately: imageryProcessing,
    };

    const url = `${API_URL}/imageryRequest`;

    dispatch(CreateImageryRequestStartAction());

    const result = await postJson<{imageryRequest: IImageryRequest}>(url, data, token);
    if (result.success) {
      dispatch(CreateImageryRequestSuccessAction());
      dispatch(UpdateImageryRequestSuccessAction(result.data?.imageryRequest));
      dispatch(UpdateProjectListImageryRequestSuccessAction(result.data?.imageryRequest));
    } else {
      dispatch(CreateImageryRequestFailedAction());
    }

    return result;
  };
