/* eslint-disable react/jsx-no-useless-fragment */
// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/system';
import { Typography, Button, CircularProgress, Fade, FormControlLabel, Checkbox } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { WideLightTooltip } from 'Components/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from 'moment-timezone';
import kmlNotUploaded from 'Assets/kml-location-inactive.png';
import kmlUploaded from 'Assets/kml-location-active.png';
import ErrorIcon from '@mui/icons-material/Error';
import ConfirmProceedDialog from 'Components/ConfirmProceedDialog';
import bbox from '@turf/bbox';
import Countdown from 'react-countdown';
import { useSelector } from 'react-redux';
import { getOrders } from 'Features/order/orderSelectors';
import { RootState } from 'Store';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { SetKmlBBoxAction } from 'Features/kml/kmlActions';
import { ResetImageryCoverageAction } from 'Features/imagery/imageryActions';
import { GetNearmapImageryCoverageThunk } from 'Features/imagery/imageryThunk';
import { PlaceOrderThunk, RemoveEstimateThunk, SubmitCustomOrderThunk } from 'Features/order/orderThunk';
import { ToggleImageryProcessingThunk } from 'Features/project/projectThunk';
import stylesDef from './styles';
import { getFeatureCollection } from 'Features/kml/kmlSelectors';
import VendorSelectionDialog from '../VendorSelectionDialog';

moment.tz.setDefault('America/New_York');

interface ImageryRequestInfoProps {
  setShowCancelDialog: (show: boolean) => void;
}

export const ImageryRequestInfo = ({ setShowCancelDialog }: ImageryRequestInfoProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const { customCheckoutForm } = useFlags();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showVendorSelectorForm, setShowVendorSelectorForm] = useState(false);
  const [showConfirmProceedDialog, setShowConfirmProceedDialog] = useState(false);
  const [imageryChecked, setImageryChecked] = useState(false);
  const { activeOrder, placedOrder } = useSelector((state: RootState) => getOrders(state));
  const kmlExists = !!(activeOrder?.boundaryFile);
  const { isEstimate, acreage } = activeOrder || {};
  const { project } = useAppSelector((state: RootState) => state.project);
  const featureCollection = useSelector((state: RootState) => getFeatureCollection(state));
  const { imageryRequest, name: projectName, _id: projectId } = project || {};
  const { kmls } = useAppSelector((state) => state.kml.present);
  const activeOrderKml = kmls[activeOrder?._id]?.featureCollection;
  const { nearmapCoverageLoading, nearmapCoverage } = useAppSelector((state: RootState) => state.imagery);

  useEffect(() => {
    if (kmlExists && !imageryRequest && activeOrderKml) {
      const kmlBBox = bbox(activeOrderKml);
      dispatch(SetKmlBBoxAction(kmlBBox));
      setImageryChecked(false);
      dispatch(ResetImageryCoverageAction());
    }
  }, [kmlExists, imageryRequest, activeOrderKml]);

  const determineCoverage = async () => {
    setImageryChecked(true);
    await dispatch(GetNearmapImageryCoverageThunk(featureCollection?.features[0]?.geometry?.coordinates[0]));
  };

  const requestImagery = () => {
    if (isEstimate) {
      setShowConfirmProceedDialog(true);
    } else {
      setShowVendorSelectorForm(true);
      window?.pendo?.track('Request Imagery', { kml_size: acreage?.toFixed(2), projectId });
    }
  };

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmProceedDialog(false);
    dispatch(RemoveEstimateThunk(activeOrder._id));
    setShowVendorSelectorForm(true);
    window?.pendo?.track('Request Imagery', { kml_size: acreage?.toFixed(2), projectId });
    e.stopPropagation();
  };

  const submitOrder = async () => {
    setIsSubmitting(true);

    if (customCheckoutForm) {
      await dispatch(SubmitCustomOrderThunk());
    } else {
      await dispatch(PlaceOrderThunk(undefined, undefined, undefined, true));
    }

    const now = moment(new Date());

    const notification = moment(new Date(imageryRequest.notificationSent)).add(1, 'd');

    const duration = moment.duration(notification.diff(now));

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;

    window?.pendo?.track('Submit Now', {
      projection: imageryRequest.projection,
      captureDate: moment(imageryRequest.captured).format('LL'),
      timeLeft: `${hours} hours, ${minutes} minutes`,
      projectName,
      totalCredits: activeOrder?.creditAcreage?.toFixed(2),
      imageryCreditCost: activeOrder?.imageryCreditCost?.toFixed(2),
      projectId: activeOrder.ownerProject,
    });
    setIsSubmitting(false);
  };

  let requested; let uploaded; let rejected; let notificationSent;

  if (imageryRequest) {
    ({ requested, uploaded, rejected, notificationSent } = imageryRequest);
  }

  const imageryRequested = requested && !rejected && !uploaded && !notificationSent;
  const imageryNotificationSent = requested && !rejected && uploaded && notificationSent;

  const toggleImageryProcessing = async () => {
    await dispatch(ToggleImageryProcessingThunk());
  };

  let imageryRequestIcon;
  let imageryRequestTitle;
  let imageryRequestDescription;
  let imageryInfo;
  let imageryRequestAction;

  if (imageryRequested && !isEstimate && !placedOrder) {
    imageryRequestIcon = <ErrorIcon sx={styles.imageryRequestIcon} fontSize="small" />;
    imageryRequestTitle = 'Imagery Requested';
    imageryRequestDescription = 'Make sure you go to checkout and save your estimate so we know exactly what you need.';
    imageryInfo = (
      <Typography sx={styles.imageryRequestDetails}>
        <Typography>
          <b>Vendor:</b>
          {' '}
          {imageryRequest.provider}
        </Typography>
      </Typography>
    );
    // This may become an edit button
    imageryRequestAction = (
      <Box sx={styles.processingContainer}>
        <FormControlLabel
          label={<Typography>Process Immediately</Typography>}
          control={(
            <Checkbox
              color="primary"
              disabled={isEstimate}
              onChange={toggleImageryProcessing}
              checked={imageryRequest.processImmediately}
              sx={{
                root: styles.checkbox,
              }}
            />
          )}
        />
        <WideLightTooltip
          placement="bottom"
          disableHoverListener={isEstimate}
          title={(
            <Box>
              When start processing immediately is selected, you are forfeiting your chance to review the available imagery for your area of interest before placing your order. If you want to review the imagery before kicking off your project, unselect this option.
            </Box>
          )}
        >
          <InfoIcon fontSize="small" />
        </WideLightTooltip>
      </Box>
    );
  } else if (imageryRequested && isEstimate && activeOrder.orderStatus === 0) {
    imageryRequestIcon = <InfoIcon sx={styles.imageryRequestIconUpdate} fontSize="small" />;
    imageryRequestTitle = 'Imagery Requested';
    imageryRequestDescription = `AirWorks has received your request for ${imageryRequest.provider} imagery. You can expect your dataset to be uploaded here in the next 24 hours.`;
    imageryInfo = <Box />;
    imageryRequestAction = (
      <Box sx={styles.imageryRequestActionContainer}>
        {imageryRequest.processImmediately ? <Typography>Processing Immediately</Typography> : <Box />}
        <Button sx={styles.imageryButton && styles.imageryProcessingButton} onClick={() => setShowCancelDialog(true)}>
          Cancel
        </Button>
      </Box>
    );
  } else if (imageryNotificationSent && !placedOrder) {
    imageryRequestIcon = <InfoIcon sx={styles.imageryRequestIconUpdate} fontSize="small" />;
    imageryRequestTitle = 'Order Placed!';
    const delivery = moment.utc(notificationSent).local().add(1, 'd').format('YYYY-MM-DD HH:mm:ss');
    imageryRequestDescription = "Your Project's order will auto-submit in:";
    imageryInfo = (
      <Box sx={styles.imageryInfoContent}>
        <AccessTimeIcon sx={styles.imageryRequestIconUpdate} fontSize="small" />
        <Typography>
          <Countdown
            date={delivery}
            renderer={(props: any) => (
              <span style={styles.timer}>
                {props.hours}
                :
                {props.minutes}
                :
                {props.seconds}
              </span>
            )}
          />
        </Typography>
      </Box>
    );
    imageryRequestAction = (
      <>
        <Button sx={styles.imageryButton} onClick={() => setShowCancelDialog(true)}>
          Cancel
        </Button>
        <Button onClick={submitOrder} sx={styles.submitButton} disabled={isSubmitting}>
          <Fade in={isSubmitting}>
            <CircularProgress size={24} sx={styles.buttonProgress} />
          </Fade>
          <Fade in={!isSubmitting}>
            <span>Submit Now</span>
          </Fade>
        </Button>

      </>
    );
  } else if (imageryNotificationSent && placedOrder) {
    imageryRequestIcon = <InfoIcon sx={styles.imageryRequestIconUpdate} fontSize="small" />;
    imageryRequestTitle = 'Order Placed!';

    imageryRequestDescription = 'Your order was placed on';
    imageryInfo = (
      <Box sx={styles.imageryInfoContent}>
        <AccessTimeIcon sx={styles.imageryRequestIconUpdate} fontSize="small" />
        <Typography>
          {moment.utc(placedOrder.orderDate).local().format('MMMM D, YYYY')}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {!kmlExists && !placedOrder ? (
        <Box sx={styles.kmlUploadInfoGrid}>
          <img style={styles.kmlUploadIcon} src={kmlNotUploaded} alt="no kml uploaded" />
          <Typography sx={styles.kmlUploadText}>
            Draw or Upload your KML to request imagery from the AirWorks Data Marketplace!
          </Typography>
        </Box>
      ) : (
        <>
          {
            (imageryRequest && !imageryRequest.rejected) ? (
              <>
                <Box sx={{ ...styles.imageryRequestCard, ...(isEstimate && styles.imageryRequestBarUpdate) }}>
                  <Box>
                    <Typography sx={styles.imageryRequestTitle}>
                      {imageryRequestIcon}
                      {imageryRequestTitle}
                    </Typography>
                    <Typography sx={styles.imageryRequestDetails}>
                      {imageryRequestDescription}
                    </Typography>
                    <Box sx={styles.imageryBottomContent}>
                      {imageryInfo}
                      {imageryRequestAction}
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box sx={styles.kmlUploadInfoGrid}>
                  <img style={styles.kmlUploadIcon} src={kmlUploaded} alt="kml uploaded" />
                  <Box>
                    <Typography sx={styles.kmlUploadText}>
                      {((imageryChecked && nearmapCoverage) || !imageryChecked)
                        ? (
                          <a href="https://awkb.scrollhelp.site/airworksknowledgebase/the-airworks-data-marketplace" style={styles.anchorTag} rel="noreferrer" target="_blank">
                            Need data for this site?
                          </a>
                        ) :
                        'Imagery is not available'}
                    </Typography>
                    {!imageryChecked && !nearmapCoverage && !nearmapCoverageLoading && (
                      <Button sx={styles.requestImageryButton} variant="text" color="primary" onClick={determineCoverage}>
                        Check Imagery Availability!
                      </Button>
                    )}
                    {imageryChecked && !nearmapCoverage && nearmapCoverageLoading && (
                      <Box sx={styles.imageryCoverageLoading}>
                        <CircularProgress size={15} />
                        <Typography sx={styles.imageryCoverageLoadingText}>Checking...</Typography>
                      </Box>
                    )}
                    {imageryChecked && nearmapCoverage && !nearmapCoverageLoading && (
                      <Button sx={styles.requestImageryButton} variant="text" color="primary" onClick={requestImagery}>
                        Request Imagery!
                      </Button>
                    )}
                    {imageryChecked && !nearmapCoverage && !nearmapCoverageLoading && (
                      <Typography sx={styles.uploadYourOwnData}> Please upload your own data.</Typography>
                    )}
                  </Box>
                </Box>
              </>
            )
          }
        </>
      )}
      <ConfirmProceedDialog
        title="Making an Imagery Request will remove your saved estimate and require you to click 'Save Estimate' again after selecting your Projection"
        contentText="Are you sure you want to proceed?"
        showDialog={showConfirmProceedDialog}
        cancelButtonText="CANCEL"
        okButtonText="PROCEED"
        onOk={onOk}
        onCancel={() => setShowConfirmProceedDialog(false)}
      />
      <VendorSelectionDialog showDialog={showVendorSelectorForm} setShowDialog={setShowVendorSelectorForm} />
    </>
  );
};

export default ImageryRequestInfo;
